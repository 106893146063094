import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import '../../../styles.scss';
import { Table, TableColumn, TableRow, TableHead, TableBody, TableCell } from '@gbm/queen-ui-guidelines';

/* istanbul ignore next */
const ItemActive = styled.div`
  background: ${props => (props.isActive ? 'green' : 'red')};
  width: 10px;
  height: 10px;
`;

class ContractGroupList extends Component {
  static propTypes = {
    list: PropTypes.array,
    onEdit: PropTypes.func,
    onDelete: PropTypes.func,
  };

  render() {
    const { list, onEdit, onDelete } = this.props;
    const listOfElements = list;

    return (
      <div className="resultsTable">
        <Table variantInherit="bordered">
          <TableHead variant="dark">
            <TableRow>
              <TableColumn className="headerAlignmentCenter">Nombre</TableColumn>
              <TableColumn className="headerAlignmentCenter">Descripción</TableColumn>
              <TableColumn className="headerAlignmentCenter">Activo</TableColumn>
              <TableColumn className="headerAlignmentCenter">Creador</TableColumn>
              <TableColumn className="headerAlignmentCenter" />
              <TableColumn className="headerAlignmentCenter" />
            </TableRow>
          </TableHead>
          <TableBody>
            {listOfElements.map(element => {
              let handleEdit = () => {
                onEdit(element);
              };
              let handleDelete = () => {
                onDelete(element);
              };

              /* istanbul ignore next */
              return (
                <TableRow key={element.id}>
                  <TableCell>{element.name}</TableCell>
                  <TableCell>{element.description}</TableCell>
                  <TableCell>{<ItemActive isActive={element.is_active} />}</TableCell>
                  <TableCell>{element.created_by}</TableCell>
                  <TableCell>
                    <a href="#" onClick={handleEdit}>
                      Editar
                    </a>
                  </TableCell>
                  <TableCell>
                    <a href="#" onClick={handleDelete}>
                      Eliminar
                    </a>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    );
  }
}

export default ContractGroupList;
