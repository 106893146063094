import React from 'react';
import PropTypes from 'prop-types';
import { Table, TableColumn, TableRow, TableHead, TableBody, TableCell } from '@gbm/queen-ui-guidelines';

const ProcessList = ({ list }) => {
  return (
    <div className="resultsTable">
      <Table variantInherit="bordered">
        <TableHead variant="dark">
          <TableRow>
            <TableColumn className="headerAlignmentCenter">Descripción</TableColumn>
            <TableColumn className="headerAlignmentCenter">Mes Inicial</TableColumn>
            <TableColumn className="headerAlignmentCenter">Mes Final</TableColumn>
          </TableRow>
        </TableHead>
        <TableBody>
          {list.map(itm => {
            return (
              <TableRow key={itm.id}>
                <TableCell>{itm.description}</TableCell>
                <TableCell>{itm.begin_year_month}</TableCell>
                <TableCell>{itm.end_year_month}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
};

ProcessList.propTypes = {
  list: PropTypes.array,
};

export default ProcessList;
