/* istanbul ignore next */
/* eslint-disable no-console */
import config from '../../config';
import { getTokenId } from 'utils/localStorage';
import axios from 'axios';
const env = config();
const { EP_BASE_URL_API } = env;

const instance = axios.create({
  baseURL: EP_BASE_URL_API,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/x-www-form-urlencoded',
  },
});
/* istanbul ignore next */
const Mantis = {
  //#region Contract Groups
  async getAllContractGroups() {
    try {
      const response = await instance.get('/contract-groups?sort=name', {
        headers: {
          Authorization: getAuthorizationHeader(),
        },
      });
      return checkStatus(response);
    } catch (err) {
      console.error(err);
      throw err;
    }
  },
  async getContractGroups({ page = 0, page_size = 10 }) {
    try {
      const response = await instance.get(`/contract-groups?page=${page},page_size=${page_size}`, {
        headers: {
          Authorization: getAuthorizationHeader(),
        },
      });
      return checkStatus(response);
    } catch (err) {
      console.error(err);
      throw err;
    }
  },
  async getContractGroupById({ id }) {
    try {
      const contractGroup = await instance.get(`/contract-groups/${id}`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: getAuthorizationHeader(),
        },
      });
      return contractGroup.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  },
  async deleteContractGroupById({ id }) {
    try {
      const contractGroup = await instance.delete(`/contract-groups/${id}`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: getAuthorizationHeader(),
        },
      });
      return contractGroup.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  },
  async postContractGroup(groupData) {
    try {
      const contractGroup = {
        name: groupData.name,
        id: groupData.id,
        description: groupData.description,
        is_active: groupData.isActive,
        created_by: groupData.created_by,
      };

      await instance.post('/contract-groups', contractGroup, {
        headers: {
          Authorization: getAuthorizationHeader(),
        },
      });
      return 'ok';
    } catch (err) {
      console.error(err);
      throw err;
    }
  },
  ////#endregion Contract Groups
  //#region Issues
  async getAllIssues() {
    try {
      const response = await instance.get('/issues?page=1,page_size=100&sort=ticker', {
        headers: {
          Authorization: getAuthorizationHeader(),
        },
      });
      return checkStatus(response);
    } catch (err) {
      console.error(err);
      throw err;
    }
  },
  async getIssues({ page = 0, page_size = 10 }) {
    try {
      const response = await instance.get(`/issues?page=${page},page_size=${page_size}`, {
        headers: {
          Authorization: getAuthorizationHeader(),
        },
      });
      return checkStatus(response);
    } catch (err) {
      console.error(err);
      throw err;
    }
  },
  async getIssueById({ id }) {
    try {
      const issue = await instance.get(`/issues/${id}`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: getAuthorizationHeader(),
        },
      });
      return issue.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  },
  async deleteIssueById({ id }) {
    try {
      const issue = await instance.delete(`/issues/${id}`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: getAuthorizationHeader(),
        },
      });
      return issue.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  },
  async postIssue(data) {
    try {
      const issue = {
        name: data.name,
        id: data.id,
        description: data.description,
        is_active: data.isActive,
        created_by: data.created_by,
      };

      await instance.post('/issues', issue, {
        headers: {
          Authorization: getAuthorizationHeader(),
        },
      });
      return 'ok';
    } catch (err) {
      console.error(err);
      throw err;
    }
  },
  ////#endregion Issues
  async getInflationAdjustmentCost({ contractGroupName, date, page = 0, pageSize = 10 }) {
    try {
      const resultsList = await instance.get(
        `/fiscal-results/short-method-transactions?page=${page},page_size=${pageSize}&q=${contractGroupName}&q_fields=contract_group_name&fiscal_date=${date}&sort=issue_ticker`,
        {
          headers: {
            Authorization: getAuthorizationHeader(),
          },
        }
      );
      return resultsList;
    } catch (err) {
      console.error(err);
      throw err;
    }
  },
  async getPortfolioConciliationByContractGroup({
    contractGroupId,
    issueId,
    page = 0,
    pageSize = 10,
    beginDate,
    endDate,
  }) {
    try {
      const resultsList = await instance.get(
        `/portfolio-reconciliations?page=${page},page_size=${pageSize}&contract_group_id=${contractGroupId}&issue_id=${issueId}&begin_date=${beginDate}&end_date=${endDate}&sort=fiscal_date`,
        {
          headers: {
            Authorization: getAuthorizationHeader(),
          },
        }
      );
      return resultsList;
    } catch (err) {
      console.error(err);
      throw err;
    }
  },
  async ExecuteShortProcessingTask(processingTask) {
    try {
      const response = await instance.post('/processing-requests/short-method-tasks', processingTask, {
        headers: {
          Authorization: getAuthorizationHeader(),
        },
      });
      return checkStatus(response);
    } catch (err) {
      console.error(err);
      throw err;
    }
  },
  async ping() {
    try {
      const response = await instance.get('/ping');
      return checkStatus(response);
    } catch (err) {
      console.error(err);
      throw err;
    }
  },
  async getInpc({ page = 0, page_size = 25 }) {
    try {
      const response = await instance.get(`/inpc?page=${page},page_size=${page_size}`, {
        headers: {
          Authorization: getAuthorizationHeader(),
        },
      });
      return checkStatus(response);
    } catch (err) {
      console.error(err);
      throw err;
    }
  },
  async getProcessingLogs({ page = 0, page_size = 25 }) {
    try {
      const response = await instance.get(`/processing-logs?page=${page},page_size=${page_size}`, {
        headers: {
          Authorization: getAuthorizationHeader(),
        },
      });
      return checkStatus(response);
    } catch (err) {
      console.error(err);
      throw err;
    }
  },
  /* istanbul ignore next */
  createNewGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = (Math.random() * 16) | 0,
        v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  },
};
/* istanbul ignore next */
export function getAuthorizationHeader() {
  const token = getTokenId();
  return token ? `Bearer ${token.value}` : null;
}
/* istanbul ignore next */
function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    response.paging = response.paging_metadata;
    return response;
  }
  const error = new Error(response.statusText);
  error.response = response;
  error.status = response.status;
  return error;
}

export default Mantis;
