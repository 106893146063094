import React from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';
import { Typography, Grid } from '@gbm/queen-ui-guidelines';

import IconGbmLogo from 'assets/images/logo-acc.png';
import IconSvg from './IconSvg';
import styled from 'styled-components';

const Background = styled.div`
  background: #fff;
`;

export class ViewSignOut extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      open: props.open,
    };

    this.timeout = setTimeout(this.setInactive, props.inactiveTimeout);
  }

  componentWillUnmount = () => {
    this.setInactive();
  };

  setInactive = () => {
    const { onSetInactive } = this.props;

    if (this.timeout) {
      this.setState({ open: false });
      clearTimeout(this.timeout);
      delete this.timeout;

      if (onSetInactive) {
        onSetInactive();
      }
    }
  };

  handleRemoveNotification = () => {
    this.setInactive();
  };

  render() {
    const { t } = this.props;

    return (
      <Background>
        <Grid container className="mt-4">
          <Grid row justify="center">
            <Grid md={8} lg={6} xl={5}>
              <div className="card">
                <div className="card-body p-5">
                  <img src={IconGbmLogo} alt="gbm" className="img-fluid mt-2" />

                  <div className="text-center">
                    <div className="mt-4">
                      <div className="logout-checkmark">
                        <IconSvg />
                      </div>
                    </div>

                    <Typography component="h3">{t('common:phrases.expiredSession')}</Typography>

                    <Typography variant="textMuted">{t('common:phrases.enterAgain')}</Typography>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Background>
    );
  }
}

ViewSignOut.propTypes = {
  t: PropTypes.func,
  open: PropTypes.bool,
  inactiveTimeout: PropTypes.number,
  onSetInactive: PropTypes.func,
};

ViewSignOut.defaultProps = {
  open: false,
  inactiveTimeout: 6000,
  onSetInactive: undefined,
};

export default withTranslation()(ViewSignOut);
