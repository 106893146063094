import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import AuthenticationApp, { Routes as AuthenticationRoutes } from '@gbm/queen-authentication';
import Home from 'containers/Home';
import SignOut from 'containers/SignOut';
import SignIn from 'containers/SignIn';
import AccessDenied from 'containers/AccessDenied';
//import VestingsQuery from 'containers/VestingQuery';
//import VestingsConfig from 'containers/VestingConfig';

import AppRoute from './AppRoute';
import ProcesoCorto from 'containers/Fiscal/AjusteCosto/ProcesoCorto';
import ConciliacionPortafolios from 'containers/Fiscal/AjusteCosto/ConciliacionPortafolios';
import Variables from 'containers/Fiscal/AjusteCosto/Variables';
import ContractGroups from 'containers/Fiscal/AjusteCosto/ContractGroups';
import Resultados from 'containers/Fiscal/AjusteCosto/Resultados';
import Resumen from 'containers/Fiscal/Reportes/Resumen';
import WithoutPermissions from 'containers/AuthProvider/WithoutPermissions';
import ProcessLog from 'containers/Fiscal/AjusteCosto/ProcessLog';
import Inpc from 'containers/Fiscal/AjusteCosto/Inpc';

/*
<AppRoute exact path="/vesting-query" component={VestingsQuery} />
<AppRoute exact path="/vesting-config" component={VestingsConfig} />
<AppRoute exact path="/AjusteCosto/ProcesoLargo" component={ProcesoLargo} />
*/

function App() {
  return (
    <Router>
      <Switch>
        <AppRoute exact path="/" component={Home} />
        <AppRoute exact path="/signout" component={SignOut} />

        <Route exact path="/signin" component={SignIn} />
        <Route exact path="/access-denied" component={AccessDenied} />
        <Route exact path="/without-permissions" component={WithoutPermissions} />

        <AppRoute exact path="/fiscal/ajuste-costo/proceso-corto" component={ProcesoCorto} />
        <AppRoute exact path="/fiscal/ajuste-costo/conciliacion-portafolios" component={ConciliacionPortafolios} />
        <AppRoute exact path="/fiscal/ajuste-costo/contract-groups" component={ContractGroups} />
        <AppRoute exact path="/fiscal/ajuste-costo/process-log" component={ProcessLog} />
        <AppRoute exact path="/fiscal/ajuste-costo/inpc" component={Inpc} />
        <AppRoute exact path="/fiscal/ajuste-costo/resultados" component={Resultados} />
        <AppRoute exact path="/fiscal/ajuste-costo/variables" component={Variables} />
        <AppRoute exact path="/fiscal/reportes/resumen" component={Resumen} />

        {AuthenticationRoutes.map(route => {
          const Component = route.component;
          return (
            <AppRoute
              key={route.path}
              exact={route.exact}
              path={route.path}
              render={() => (
                <AuthenticationApp>
                  <Component />
                </AuthenticationApp>
              )}
            />
          );
        })}
      </Switch>
    </Router>
  );
}

export default App;
