export const RESTART_ON_REMOUNT = '@@saga-injector/restart-on-remount';
export const DAEMON = '@@saga-injector/daemon';
export const ONCE_TILL_UNMOUNT = '@@saga-injector/once-till-unmount';

export const API_EP = {
  PING: '/ping',
  TOKEN: '/oauth2/token',
  AUTHORIZE: '/oauth2/authorize?',
  LOGOUT: '/logout?',
  GRANT_TYPE: 'authorization_code',
  LOGIN: '/login?',
};
