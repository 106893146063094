import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { Typography, Icon, Grid } from '@gbm/queen-ui-guidelines';

function AccessDenied() {
  const { t } = useTranslation();

  return (
    <Grid container className="mt-4">
      <Grid row justify="center">
        <Grid md={8} lg={6} xl={5}>
          <div className="card">
            <div className="card-body p-4">
              <div className="error-ghost text-center">
                <div className="display-1">
                  <Icon className="remixicon-error-warning-fill" />
                </div>
              </div>

              <div className="text-center">
                <Typography component="h1">{t('common:phrases.accessDenied')}</Typography>
                <Typography variant="textMuted" className="mb-4 mt-3">
                  {t('common:phrases.accessDeniedMessage')}
                </Typography>

                <Link className="btn btn-primary mt-3" to="/">
                  <Icon className="mdi mdi-reply mr-1"></Icon> {t('common:phrases.returnHome')}
                </Link>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
}

AccessDenied.propTypes = {
  t: PropTypes.func,
};

export default AccessDenied;
