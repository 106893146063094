import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';

import clsx from 'clsx';

import { Icon, Dropdown, DropdownItem } from '@gbm/queen-ui-guidelines';

import IconGbmLogo from 'assets/images/gbmacc-platform-black.png';

import styles from './header.module.scss';

class Header extends React.PureComponent {
  state = {
    openDropdown: false,
  };

  handleOpenDropdown = () => {
    const { openDropdown } = this.state;
    this.setState({
      openDropdown: !openDropdown,
    });
  };

  handleCloseDropdown = () => {
    this.setState({
      openDropdown: false,
    });
  };

  render() {
    const { openDropdown } = this.state;
    const { username, onSmall, small } = this.props;

    const classesLogo = clsx({
      [styles.logo_container]: true,
      [styles.logo_container__small]: small,
    });

    const classesLogoLg = clsx({
      [`img-fluid ${styles.logo_lg}`]: true,
      [styles.hide]: small,
    });

    //

    return (
      <header className={styles.root}>
        <div className={styles.nav}>
          <div className={classesLogo}>
            <Link to="/">
              <img src={IconGbmLogo} alt="gbm" className={classesLogoLg} />
            </Link>
          </div>

          <button type="button" className={styles.menu} onClick={onSmall}>
            <Icon className="fe-menu" />
          </button>

          <div className={styles.spacer}></div>

          <div className={styles.dropdown_wrapper}>
            <Dropdown
              id="dropdown"
              variant="button"
              open={openDropdown}
              className={styles.dropdown}
              label={username}
              color="#casa"
              iconButton={
                openDropdown ? (
                  <Icon className="fas fa-chevron-up ml-3" />
                ) : (
                  <Icon className="fas fa-chevron-down ml-3" />
                )
              }
              onOpen={this.handleOpenDropdown}
              onClose={this.handleCloseDropdown}>
              <DropdownItem className={styles.dropdownitem}>
                <Link to="/signout" className="dropdown-item notify-item">
                  <Icon className="remixicon-logout-box-line mr-2" />
                  <Trans i18nKey="common:navigation.signout" />
                </Link>
              </DropdownItem>
            </Dropdown>
          </div>
        </div>
      </header>
    );
  }
}

Header.propTypes = {
  username: PropTypes.string,
  small: PropTypes.bool,
  onSmall: PropTypes.func,
};

export default Header;
