import React, { Fragment, useState } from 'react';
import Mantis from 'api/mantis';
import ProcessForm from './ProcessForm';
import ProcessList from './ProcessList';
import { CardBox, Notification } from '@gbm/queen-ui-guidelines';

const ProcesoCorto = () => {
  const [error, setError] = useState({ error: false, errorMessage: 'No Error' });
  const [processList, setProcessList] = useState([]);

  /* istanbul ignore next */
  function handleSubmit(response) {
    setError({ error: false, errorMessage: 'Not error' });
    let array = [];
    try {
      response.forEach(processingTask => {
        array.push(processingTask);
        Mantis.ExecuteShortProcessingTask(processingTask);
      });
      setProcessList(array);
    } catch (error) {
      setError({ error: true, errorMessage: error });
    }
  }

  return (
    <Fragment>
      <h2 className="header">Proceso Corto</h2>
      <CardBox className="divFilter">
        <ProcessForm handleSubmit={e => handleSubmit(e)} />
      </CardBox>
      {processList.length > 0 ? (
        <div>
          <div>Peticiones enviadas:</div>
          <ProcessList list={processList} />
        </div>
      ) : null}
      <Notification open={error.error} color="danger" colorInherit>
        {error.errorMessage}
      </Notification>
    </Fragment>
  );
};

export default ProcesoCorto;
