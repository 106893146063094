import React, { Component } from 'react';
import PropTypes from 'prop-types';
import '../../../styles.scss';
import { Table, TableColumn, TableRow, TableHead, TableBody, TableCell } from '@gbm/queen-ui-guidelines';

class ProcessLogList extends Component {
  static propTypes = {
    list: PropTypes.array,
    onEdit: PropTypes.func,
    onDelete: PropTypes.func,
  };

  render() {
    const { list } = this.props;
    const listOfElements = list;

    return (
      <div className="resultsTable">
        <Table variantInherit="bordered">
          <TableHead variant="dark">
            <TableRow>
              <TableColumn className="headerAlignmentCenter">Compañia</TableColumn>
              <TableColumn className="headerAlignmentCenter">Emisora</TableColumn>
              <TableColumn className="headerAlignmentCenter">Fecha Inicial</TableColumn>
              <TableColumn className="headerAlignmentCenter">Fecha Final</TableColumn>
              <TableColumn className="headerAlignmentCenter">Estatus</TableColumn>
              <TableColumn className="headerAlignmentCenter">Fecha Ejecucion</TableColumn>
            </TableRow>
          </TableHead>
          <TableBody>
            {listOfElements.map(element => {
              /* istanbul ignore next */
              return (
                <TableRow key={element.id}>
                  <TableCell>{element.contract_group_name}</TableCell>
                  <TableCell>{element.issue_ticker}</TableCell>
                  <TableCell>{element.begin_month}</TableCell>
                  <TableCell>{element.end_month}</TableCell>
                  <TableCell>{element.status}</TableCell>
                  <TableCell>{element.created_at}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    );
  }
}

export default ProcessLogList;
