import React from 'react';

import Kudy from 'kudyjs';

import styles from './styles.module.scss';
import styled from 'styled-components';

const Background = styled.div`
  color: #095eb3;
`;
function Home() {
  const { phrase, author } = Kudy.make();

  return (
    <div className={styles.phrase_container}>
      <span className={styles.space} />

      <blockquote>
        <Background>{phrase}</Background>
      </blockquote>
      <p className={styles.phrase_author}>{author}</p>
    </div>
  );
}

export default Home;
