import React, { Fragment, useState, useEffect } from 'react';
import Mantis from 'api/mantis';
import {
  Table,
  TableColumn,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Icon,
  Button,
  Spinner,
  Notification,
} from '@gbm/queen-ui-guidelines';
import CustomDropdown from 'components/CustomDropdown';
import CustPagination from '../../../../components/CustPagination';
import './ConciliacionPortafolios.scss';
import '../../styles.scss';
import { CardBox } from '@gbm/queen-ui-guidelines/dist/components';

const ConciliacionPortafolios = () => {
  var today = new Date();
  var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  var yyyy = today.getFullYear();

  const [queryResult, setQueryResult] = useState([]);
  const [usedSearch, setUsedSearch] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingCatalogs, setLoadingCatalogs] = useState(true);
  const [pagination, setPagination] = useState({
    total_items: 0,
    page_size: 25,
    page_count: 0,
    page: 1,
    next: '',
    previous: '',
  });
  const [contractGroupList, setContractGroupList] = useState([]);
  const [selectedContractGroupItem, setSelectedContractGroupItem] = useState();
  const [issueList, setIssueList] = useState([]);
  const [selectedIssueItem, setSelectedIssueItem] = useState();
  const [minDateValue, setMinDateValue] = useState();
  const [maxDateValue, setMaxDateValue] = useState();
  const [selectedBeginMonth, setSelectedBeginMonth] = useState(yyyy + '-' + mm);
  const [selectedEndMonth, setSelectedEndMonth] = useState(yyyy + '-' + mm);
  const [error, setError] = useState({ error: false, errorMessage: 'No Error' });
  const [request, setRequest] = useState();

  let contractGroupsLoaded = false;
  let issuesLoaded = false;

  /* istanbul ignore next */
  useEffect(() => {
    setMinDateValue('2006-01');
    setMaxDateValue(yyyy + '-' + mm);
    updateContractGroupList();
    updateIssueList();
  }, []);

  /* istanbul ignore next */
  function onSubmit() {
    setError({ error: false, errorMessage: 'Not error' });
    if (selectedContractGroupItem !== null) {
      handlePageClick(1);
    }
  }

  /* istanbul ignore next */
  function handlePageSelected(data) {
    if (pagination.page !== data.currentPage) handlePageClick(data.currentPage);
  }

  /* istanbul ignore next */
  function handlePageClick(selectedPage) {
    setRequest({
      contractGroupName: selectedContractGroupItem.name,
      ticker: selectedIssueItem.ticker,
      page: selectedPage,
      pageSize: pagination.page_size,
      beginMonth: selectedBeginMonth,
      endMonth: selectedEndMonth,
    });
    setIsLoading(true);
    Mantis.getPortfolioConciliationByContractGroup({
      contractGroupId: selectedContractGroupItem.id,
      issueId: selectedIssueItem.id,
      page: selectedPage,
      pageSize: pagination.page_size,
      beginDate: GetFirstDayOfMonth(selectedBeginMonth),
      endDate: GetLastDayOfMonth(selectedEndMonth),
    }).then(results => {
      handleResults(results);
      setIsLoading(false);
    });
  }

  /* istanbul ignore next */
  function handleResults(results) {
    let pagination = results.data.pagination_metadata;
    setPagination({
      total_items: pagination.total_items,
      page_count: pagination.page_count,
      page: pagination.page,
      page_size: pagination.page_size,
    });
    setQueryResult(results.data.items);
    setUsedSearch(true);
  }

  /* istanbul ignore next */
  function renderResults() {
    return queryResult.length === 0 ? (
      <div className="notFoundMessage">
        <p>Ups! No se encontraron resultados :(</p>
      </div>
    ) : (
      <div>
        <p>
          Página {pagination.page} de {Math.ceil(pagination.total_items / pagination.page_size)}
        </p>
        <div className="resultsTable">
          <Table variantInherit="bordered">
            <TableHead variant="dark">
              <TableRow key={pagination.page}>
                <TableColumn className="headerAlignmentCenter">Fecha</TableColumn>
                <TableColumn className="headerAlignmentCenter">Emisora</TableColumn>
                <TableColumn className="headerAlignmentCenter">Títulos Fiscal</TableColumn>
                <TableColumn className="headerAlignmentCenter">Títulos SOB</TableColumn>
                <TableColumn className="headerAlignmentCenter">Diferencia</TableColumn>
              </TableRow>
            </TableHead>
            <TableBody>
              {queryResult.map(element => {
                return (
                  <TableRow key={element.id}>
                    <TableCell className="cellAlignmentLeft">{formatDate(element.fiscal_date)}</TableCell>
                    <TableCell className="cellAlignmentLeft">{element.issue_ticker}</TableCell>
                    <TableCell className="cellAlignmentRight">{numericFormat(element.ending_fiscal_shares)}</TableCell>
                    <TableCell className="cellAlignmentRight">
                      {numericFormat(element.ending_financial_shares)}
                    </TableCell>
                    <TableCell className={element.ending_shares_differences !== 0 ? 'invalidNumber' : 'validNumber'}>
                      {numericFormat(element.ending_shares_differences)}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
      </div>
    );
  }

  /* istanbul ignore next */
  const updateContractGroupList = () => {
    try {
      Mantis.getAllContractGroups().then(results => {
        const items = results.data.items;
        setContractGroupList(items);
        setSelectedContractGroupItem(items[0]);
        contractGroupsLoaded = true;
        setLoadingCatalogs(!(contractGroupsLoaded && issuesLoaded));
      });
    } catch (error) {
      setError({ error: true, errorMessage: 'Tenemos un problema al obtener la lista de compañías' });
    }
  };

  /* istanbul ignore next */
  const updateIssueList = () => {
    try {
      Mantis.getAllIssues().then(results => {
        const items = results.data.items;
        setIssueList(items);
        setSelectedIssueItem(items[0]);
        issuesLoaded = true;
        setLoadingCatalogs(!(contractGroupsLoaded && issuesLoaded));
      });
    } catch (error) {
      setError({ error: true, errorMessage: 'Tenemos un problema al obtener la lista de emisoras' });
    }
  };

  /* istanbul ignore next */
  function numericFormat(element) {
    return new Intl.NumberFormat('en-US', {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(element);
  }

  /* istanbul ignore next */
  function formatDate(date) {
    date = new Date(date);
    var dateStr = `${date.getFullYear()}-${('00' + (date.getMonth() + 1)).slice(-2)}-${('00' + date.getDate()).slice(
      -2
    )}`;
    return dateStr;
  }

  /* istanbul ignore next */
  function GetFirstDayOfMonth(month) {
    var firstDayOfMonth = new Date(month + '-01');
    var mm = firstDayOfMonth.getMonth() + 1; //January is 0!
    var yyyy = firstDayOfMonth.getFullYear();
    firstDayOfMonth = formatDate(new Date(yyyy, mm, 1));
    return firstDayOfMonth;
  }

  /* istanbul ignore next */
  function GetLastDayOfMonth(month) {
    var lastDayOfMonth = new Date(month + '-01');
    var mm = lastDayOfMonth.getMonth() + 2; //January is 0!
    var yyyy = lastDayOfMonth.getFullYear();
    lastDayOfMonth = formatDate(new Date(yyyy, mm, 0));
    return lastDayOfMonth;
  }

  /* istanbul ignore next */
  return (
    <Fragment>
      <h2 className="header">Conciliación de Portafolios</h2>
      <CardBox className="divFilter">
        {loadingCatalogs ? (
          <Spinner />
        ) : (
          <div className="row">
            <div className="col-md-3">
              <div className="black-label">Compañía:</div>
              <CustomDropdown
                itemsSource={contractGroupList}
                value="id"
                displayName="name"
                onSelectionChanged={e => {
                  setSelectedContractGroupItem({ id: e.id, name: e.name });
                }}
              />
            </div>
            <div className="col-md-2">
              <div className="black-label">Emisora:</div>
              <CustomDropdown
                itemsSource={issueList}
                value="id"
                displayName="ticker"
                onSelectionChanged={e => {
                  setSelectedIssueItem({ id: e.id, ticker: e.ticker });
                }}
              />
            </div>
            <div className="col-md-2">
              <div className="black-label">Fecha inicial:</div>
              <input
                type="month"
                className="white-month-picker"
                defaultValue={selectedBeginMonth}
                value={selectedBeginMonth}
                min={minDateValue}
                max={maxDateValue}
                onChange={e => {
                  setSelectedBeginMonth(e.target.value);
                }}
              />
            </div>
            <div className="col-md-2">
              <div className="black-label">Fecha final:</div>
              <input
                type="month"
                className="white-month-picker"
                defaultValue={selectedEndMonth}
                value={selectedEndMonth}
                min={minDateValue}
                max={maxDateValue}
                onChange={e => {
                  setSelectedEndMonth(e.target.value);
                }}
              />
            </div>
            <div className="col-md-2">
              <div>.</div>
              <Button bordered={false} color="warning" type="button" onClick={() => onSubmit()}>
                <Icon className="fas fa-search" component="i" />
              </Button>
            </div>
          </div>
        )}
      </CardBox>
      {usedSearch ? (
        <div className="divPaginator">
          <CustPagination
            totalRecords={pagination.total_items}
            pageLimit={pagination.page_size}
            pageNeighbours={2}
            onPageChanged={handlePageSelected}
          />
        </div>
      ) : null}
      {isLoading ? (
        <Spinner />
      ) : usedSearch ? (
        <div className="divData">
          <h4 className="subHeader">
            Cuadre de posición : {request.contractGroupName} | {request.ticker} | del {request.beginMonth} {}
            al {request.endMonth}
          </h4>
          {renderResults()}
        </div>
      ) : (
        <div className="searchText">
          <p>Por favor realice una búsqueda</p>
        </div>
      )}
      <Notification open={error.error} color="danger" colorInherit>
        {error.errorMessage}
      </Notification>
    </Fragment>
  );
};
export default ConciliacionPortafolios;
