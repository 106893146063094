import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import { ENVIRONMENTS } from '../../constants';

const NewRelic = ({ environment }) => (
  <>
    {environment === ENVIRONMENTS.PRODUCTION && (
      <Helmet>
        <script src="scripts/newrelic_prd.js" />
      </Helmet>
    )}

    {(environment === ENVIRONMENTS.LOCAL ||
      environment === ENVIRONMENTS.STAGE ||
      environment === ENVIRONMENTS.DEVELOPMENT) && (
      <Helmet>
        <script src="scripts/newrelic_stg.js" />
      </Helmet>
    )}
  </>
);

NewRelic.propTypes = {
  environment: PropTypes.string.isRequired,
};

export default NewRelic;
