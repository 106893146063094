import axios from 'axios';
import qs from 'qs';

import config from '../config';
import { API_AUTH } from './constants';

const env = config();
const { EP_AUTH_URL, EP_AUTH_CLIENT_ID, EP_AUTH_REDIRECT_URI } = env;

const Api = {
  async requestToken(code) {
    try {
      const params = qs.stringify({
        grant_type: API_AUTH.GRANT_TYPE,
        client_id: EP_AUTH_CLIENT_ID,
        redirect_uri: EP_AUTH_REDIRECT_URI,
        code,
      });

      const response = await axios.post(`${EP_AUTH_URL}${API_AUTH.TOKEN}`, params, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });

      const { status, data } = response;

      return { status, data };
    } catch (err) {
      const { status, data } = err.response;

      if (status) {
        return { status, data };
      }

      return err.response;
    }
  },
};

export default Api;
