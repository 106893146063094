import { PureComponent } from 'react';

import Auth from 'utils/auth';

const auth = new Auth();

class SignOut extends PureComponent {
  componentDidMount() {
    auth.signOut();
  }

  render() {
    return null;
  }
}

export default SignOut;
