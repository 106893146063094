import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from '@gbm/queen-ui-guidelines';
import PropTypes from 'prop-types';
import '../../../styles.scss';

/* istanbul ignore next */
function ModalEditContractGroup({ contractGroup, isOpen, onClose, onSubmit }) {
  const { id, name, description, created_by, is_active } = contractGroup;

  const handleSubmit = () => {
    onSubmit();
  };

  /*
        handleSaveButtonClick = e => {
        const { cGroupData } = this.state;
        e.preventDefault();
        Mantis.postContractGroup(cGroupData);
        };
    */
  return (
    <Modal centered isOpen={isOpen} size="xl" scrollable>
      <ModalHeader onClose={onClose}>Contract Group</ModalHeader>
      <ModalBody>
        <div>
          <table>
            <tbody>
              <tr>
                <td>
                  <label>ID:</label>
                </td>
                <td>
                  <input type="text" value={id} />
                </td>
              </tr>
              <tr>
                <td>
                  <label>NOMBRE:</label>
                </td>
                <td>
                  <input type="text" value={name} />
                </td>
              </tr>
              <tr>
                <td>
                  <label>DESCRIPCION:</label>
                </td>
                <td>
                  <input type="text" value={description} />
                </td>
              </tr>
              <tr>
                <td>
                  <label>CREADO POR:</label>
                </td>
                <td>
                  <input type="text" value={created_by} />
                </td>
              </tr>
              <tr>
                <td>
                  <label>ACTIVO:</label>
                </td>
                <td>
                  <input type="checkbox" checked={is_active} />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button className="searchButton" type="button" onClick={handleSubmit}>
          Aceptar
        </Button>
        <Button color="danger" type="button" onClick={onClose}>
          Cancelar
        </Button>
      </ModalFooter>
    </Modal>
  );
}

ModalEditContractGroup.propTypes = {
  contractGroup: PropTypes.object,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default ModalEditContractGroup;

/*

*/
