/**
 * i18n.js
 *
 * This will setup the i18next library
 *
 */
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import 'moment/locale/es';
import moment from 'moment-timezone';

moment.locale('es');
moment.tz.setDefault('America/Mexico_City');

i18n
  .use(initReactI18next)
  .use(Backend)
  .use(LanguageDetector)
  .init(
    {
      lng: 'es-MX',
      fallbackLng: ['es-MX'],
      load: 'currentOnly',
      backend: {
        loadPath: `${process.env.PUBLIC_PATH || '/'}locales/{{lng}}/{{ns}}.json`,
      },
      ns: ['common'],
      defaultNS: 'common',
      interpolation: {
        escapeValue: false,
        /* istanbul ignore next */
        format: /* istanbul ignore next */ (value, format) => {
          /* istanbul ignore next */
          if (moment.isMoment(value)) {
            return value.format(format);
          }
          /* istanbul ignore next */
          return value;
        },
      },
      react: {
        wait: true,
      },
    },
    /* istanbul ignore next */
    () => {
      moment.updateLocale(moment.locale(), {
        invalidDate: i18n.t('common:phrases.notAvailable'),
        monthsShort: i18n.t('common:i18n.monthsShort').split('_'),
      });
    }
  );

export default i18n;
