/**
 * Config Backoffice
 */

const environments = {
  LOCAL: {
    debugLang: true,
    ep: {
      apiUrl: 'https://api.accounting.dev-gbm.com/v1',
      authUrl: 'https://patron-backoffice-dev-1596325.auth.us-east-1.amazoncognito.com',
      authClientId: '6p6ehh2jto59k5rc3g2bltehnu',
      authRedirectUri: 'http://localhost:3002/signin',
      authLogoutUri: 'http://localhost:3002/signin',
    },
    featureFlags: {
      moduleLorem: true,
      moduleCash: true,
    },
    accountingGroup: 'accounting-dev',
  },
  dev: {
    debugLang: true,
    ep: {
      apiUrl: 'https://api.accounting.dev-gbm.com/v1',
      authUrl: 'https://patron-backoffice-dev-1596325.auth.us-east-1.amazoncognito.com',
      authClientId: '6p6ehh2jto59k5rc3g2bltehnu',
      authRedirectUri: 'https://accounting.dev-gbm.com/signin',
      authLogoutUri: 'https://accounting.dev-gbm.com/signin',
    },
    featureFlags: {
      moduleLorem: true,
      moduleCash: true,
    },
    accountingGroup: 'accounting-dev',
  },
  stg: {
    debugLang: true,
    ep: {
      apiUrl: 'https://api.accounting.stg-gbm.com/v1',
      authUrl: 'https://patron-backoffice-stg-1596325.auth.us-east-1.amazoncognito.com',
      authClientId: 'h0k76dhjr43hvemn6luekf5cc',
      authRedirectUri: 'https://accounting.stg-gbm.com/signin',
      authLogoutUri: 'https://accounting.stg-gbm.com/signin',
    },
    featureFlags: {
      moduleLorem: false,
      moduleCash: false,
    },
    accountingGroup: 'accounting',
  },
  prd: {
    debugLang: true,
    ep: {
      apiUrl: 'https://api.accounting.gbm.com/v1',
      authUrl: 'https://patron-backoffice-prd-1596325.auth.us-east-1.amazoncognito.com',
      authClientId: '3va72k30pa2lieu6n91rnbu8gk',
      authRedirectUri: 'https://accounting.gbm.com/signin',
      authLogoutUri: 'https://accounting.gbm.com/signin',
    },
    featureFlags: {
      moduleLorem: false,
      moduleCash: false,
    },
    accountingGroup: 'accounting',
  },
};

/* istanbul ignore next */
const config = (appEnv = process.env.REACT_APP_ENV) => {
  const env = {
    /* istanbul ignore next */
    NODE_ENV: process.env.NODE_ENV || /* istanbul ignore next */ 'development',
    APP_ENV: getEnv(appEnv),
  };

  env.EP_BASE_URL_API = environments[env.APP_ENV].ep.apiUrl;
  env.EP_AUTH_URL = environments[env.APP_ENV].ep.authUrl;
  env.EP_AUTH_CLIENT_ID = environments[env.APP_ENV].ep.authClientId;
  env.REACT_APP_DEBUG_LANG = environments[env.APP_ENV].debugLang;
  env.EP_AUTH_REDIRECT_URI = environments[env.APP_ENV].ep.authRedirectUri;
  env.EP_AUTH_LOGOUT_URI = environments[env.APP_ENV].ep.authLogoutUri;
  env.FEATURE_FLAGS = environments[env.APP_ENV].featureFlags;
  env.ACCOUNTING_GROUP = environments[env.APP_ENV].accountingGroup;

  return env;
};

const getEnv = appEnv => {
  let environment = appEnv || 'LOCAL';
  if (Object.keys(environments).indexOf(environment) < 0) {
    environment = 'LOCAL';
  }
  return environment;
};

export default config;
