import React, { Component } from 'react';
import PropTypes from 'prop-types';
import '../../../styles.scss';
import { Table, TableColumn, TableRow, TableHead, TableBody, TableCell } from '@gbm/queen-ui-guidelines';

/* istanbul ignore next */
class ResultsList extends Component {
  static propTypes = {
    list: PropTypes.array,
  };

  render() {
    const { list } = this.props;
    const listOfElements = list;

    return (
      <div className="resultsTable">
        <Table variantInherit="bordered">
          <TableHead variant="dark">
            <TableRow>
              <TableColumn className="headerAlignmentCenter">Emisora</TableColumn>
              <TableColumn className="headerAlignmentCenter">Tipo Movto.</TableColumn>
              <TableColumn className="headerAlignmentCenter">Títulos Movto.</TableColumn>
              <TableColumn className="headerAlignmentCenter">Costo/Precio Total</TableColumn>
              <TableColumn className="headerAlignmentCenter">Saldo Títulos</TableColumn>
              <TableColumn className="headerAlignmentCenter">Costo/Precio Fiscal Unit. Act.</TableColumn>
              <TableColumn className="headerAlignmentC9enter">Costo/Precio Fiscal Total Act.</TableColumn>
              <TableColumn className="headerAlignmentC9enter">Resultado Fiscal</TableColumn>
            </TableRow>
          </TableHead>
          <TableBody>
            {listOfElements.map(element => {
              return (
                <TableRow key={element.id}>
                  {/*[Emisora]*/}
                  <TableCell>{element.issue_ticker}</TableCell>
                  {/*Tipo Movto.*/}
                  <TableCell>{element.transaction_type_name}</TableCell>
                  {/*Títulos Movto.*/}
                  <TableCell className="numeric">
                    {new Intl.NumberFormat().format(element.transaction_shares)}
                  </TableCell>
                  {/*Costo/Precio Total*/}
                  <TableCell className="numeric">
                    {new Intl.NumberFormat('en-US', {
                      style: 'decimal',
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(element.transaction_financial_cost)}
                  </TableCell>
                  {/*Saldo Títulos*/}
                  <TableCell className="numeric">{new Intl.NumberFormat().format(element.ending_shares)}</TableCell>
                  {/*Costo/Precio Fiscal Unit. Act.*/}
                  <TableCell className="numeric">
                    {new Intl.NumberFormat('en-US', {
                      style: 'decimal',
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(element.ending_fiscal_price_per_share)}
                  </TableCell>
                  {/*Costo/Precio Fiscal Total Act.*/}
                  <TableCell className="numeric">
                    {new Intl.NumberFormat('en-US', {
                      style: 'decimal',
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(element.ending_fiscal_cost)}
                  </TableCell>
                  {/*Resultado Fiscal*/}
                  <TableCell className="numeric">
                    {new Intl.NumberFormat('en-US', {
                      style: 'decimal',
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(element.transaction_fiscal_profit_loss)}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    );
  }
}

export default ResultsList;
