import React, { useState, useEffect, useRef, Fragment } from 'react';
import PropTypes from 'prop-types';
import './AutoCompleteDropDown.scss';

/* istanbul ignore next */
const AutoCompleteDropDown = ({ value, displayName, itemsSource, onSelectionChanged }) => {
  const [filteredItemList, setFilteredItemList] = useState(itemsSource);
  const [showItemList, setShowItemList] = useState(false);
  const [userInput, setUserInput] = useState();
  const [activeItem, setActiveItem] = useState(0);
  const wrapperRef = useRef(null);

  useEffect(() => {
    /*Alert if clicked on outside of element*/
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        onLostFocus();
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  function onChange(e) {
    const userInput = e.currentTarget.value;

    if (userInput == '') {
      setFilteredItemList(itemsSource);
      setShowItemList(true);
    } else {
      const filteredItems = itemsSource.filter(
        item =>
          item[displayName]
            .toString()
            .toLowerCase()
            .indexOf(userInput.toLowerCase()) > -1
      );
      setActiveItem(0);
      setFilteredItemList(filteredItems);
      setShowItemList(true);
    }

    setUserInput(e.currentTarget.value);
  }

  function onInputClick() {
    setShowItemList(!showItemList);
  }

  function onItemClick(item) {
    onSelectionChanged(item);
    setShowItemList(false);
    setActiveItem(-1);
    setFilteredItemList(itemsSource);
    setUserInput(item[displayName]);
  }

  function onLostFocus() {
    setActiveItem(0);
    setShowItemList(false);
  }

  function onKeyDown(e) {
    if (e.keyCode === 13) {
      /* User pressed the return key*/
      const item = filteredItemList[activeItem];
      setActiveItem(0);
      setShowItemList(false);
      setUserInput(item[displayName]);
      onSelectionChanged(item);
    } else if (e.keyCode === 38) {
      /* User pressed the up arrow, decrease the index */
      if (activeItem === 0) {
        return;
      }
      setActiveItem(activeItem - 1);
    } else if (e.keyCode === 40) {
      /* User pressed the down arrow, increment the index */
      if (activeItem - 1 === filteredItemList.length) {
        return;
      }
      setActiveItem(activeItem + 1);
    }
  }

  function renderItems() {
    let suggestionsListComponent;

    if (showItemList) {
      if (filteredItemList.length) {
        suggestionsListComponent = (
          <ul className="suggestions">
            {filteredItemList.map((item, index) => {
              let className;

              if (index == activeItem) {
                className = 'suggestion-active';
              }

              return (
                <li
                  id={item[value]}
                  key={item[value]}
                  value={item[value]}
                  className={className}
                  onClick={() => {
                    onItemClick(item);
                  }}>
                  {item[displayName]}
                </li>
              );
            })}
          </ul>
        );
      } else {
        suggestionsListComponent = (
          <div className="no-suggestions">
            <em>No se encontraron coincidencias.</em>
          </div>
        );
      }
    }

    return suggestionsListComponent;
  }

  return (
    <Fragment>
      <div ref={wrapperRef}>
        <div className="inner-addon">
          <input type="text" onChange={onChange} onKeyDown={onKeyDown} value={userInput} onClick={onInputClick} />
          <i className="fas fa-chevron-down ml-3" onClick={onInputClick} />
        </div>
        {renderItems()}
      </div>
    </Fragment>
  );
};

export default AutoCompleteDropDown;

AutoCompleteDropDown.propTypes = {
  value: PropTypes.string,
  displayName: PropTypes.string,
  itemsSource: PropTypes.array,
  onSelectionChanged: PropTypes.func,
};
