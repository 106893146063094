import React from 'react';
import PropTypes from 'prop-types';

const CustomDropdown = ({ value, displayName, itemsSource, onSelectionChanged }) => {
  /* istanbul ignore next */
  return (
    <select
      className="white-select"
      onChange={e => {
        let selectedItem = itemsSource.find(x => x[value] === e.target.value);
        onSelectionChanged(selectedItem);
      }}>
      {itemsSource.map(element => {
        return (
          <option key={element[value]} value={element[value]}>
            {element[displayName]}
          </option>
        );
      })}
    </select>
  );
};

CustomDropdown.propTypes = {
  value: PropTypes.string,
  displayName: PropTypes.string,
  itemsSource: PropTypes.array,
  onSelectionChanged: PropTypes.func,
};

export default CustomDropdown;
