import React from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

// import config from 'config';

import Item from './Item';
import styles from './sidebar.module.scss';

// const ENV = config();

function Sidebar({ small }) {
  const { t } = useTranslation();

  const classesRoot = clsx({
    [styles.sidebar]: true,
    [styles.sidebar_small]: small,
  });

  const classesTitle = clsx({
    [styles.title]: true,
    [styles.hide]: small,
  });

  return (
    <div className={classesRoot}>
      <ul className={styles.menu_list}>
        <li className={classesTitle}>{t('common:navigation.navigationFiscal')}</li>
        <Item
          label={t('common:navigation.linkAjusteCosto')}
          icon="remixicon-money-dollar-circle-line"
          small={small}
          links={[
            {
              to: '/fiscal/ajuste-costo/proceso-corto',
              label: t('common:navigation.linkProcesoCorto'),
              icon: 'remixicon-settings-2-fill',
            },
            {
              to: '/fiscal/ajuste-costo/process-log',
              label: t('common:navigation.linkProcessLog'),
              icon: 'remixicon-grid-fill',
            },
            {
              to: '/fiscal/ajuste-costo/conciliacion-portafolios',
              label: t('common:navigation.linkConciliacionPortafolios'),
              icon: 'remixicon-briefcase-line',
            },
            /*
            {
              to: '/fiscal/ajuste-costo/variables',
              label: t('common:navigation.linkVariables'),
              icon: 'remixicon-file-settings-line',
            },*/
            {
              to: '/fiscal/ajuste-costo/resultados',
              label: t('common:navigation.linkResultados'),
              icon: 'remixicon-bar-chart-fill',
            },
          ]}
        />
        <Item
          label={t('common:navigation.linkCatalogos')}
          icon="remixicon-pages-line"
          small={small}
          links={[
            {
              to: '/fiscal/ajuste-costo/contract-groups',
              label: t('common:navigation.linkContractGroups'),
              icon: 'remixicon-building-2-line',
            },
            {
              to: '/fiscal/ajuste-costo/inpc',
              label: t('common:navigation.linkInpc'),
              icon: 'remixicon-bar-chart-2-fill',
            },
          ]}
        />
      </ul>
    </div>
  );
}

/*
<Item
          label={t('common:navigation.linkReportes')}
          icon="remixicon-pages-line"
          small={small}
          links={[
            {
              to: '/fiscal/reportes/resumen',
              label: t('common:navigation.linkResumen'),
              icon: 'remixicon-book-3-line',
            },
          ]}
        />
*/

Sidebar.propTypes = {
  small: PropTypes.bool,
};

export default Sidebar;
