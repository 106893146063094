import React from 'react';

import { Route, withRouter } from 'react-router-dom';

import ViewSignOut from 'components/ViewSignOut';
import ViewLogIn from 'components/ViewLogIn';
import Layout from 'components/Layout';

import Auth from 'utils/auth';
import { getTokenId, removeTokenId } from 'utils/localStorage';

export function AppRoute({ ...other }) {
  const auth = new Auth();
  const tokenId = getTokenId();

  function redirectSignin() {
    const { history } = other;

    removeTokenId();
    history.push('/signin');
  }

  if (!tokenId && !auth.isValidToken()) {
    return <ViewLogIn onLogIn={redirectSignin} />;
  }

  if (tokenId && !auth.isTokenExpired()) {
    return (
      <Layout>
        <Route {...other} />
      </Layout>
    );
  }

  return <ViewSignOut inactiveTimeout={3000} onSetInactive={redirectSignin} />;
}

export default withRouter(AppRoute);
