import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Button, Margin } from '@gbm/queen-ui-guidelines';
import Auth from 'utils/auth';
import { useTranslation } from 'react-i18next';
import PageMessage from 'components/PageMessage';
import { ReactComponent as IconAccesDenied } from 'assets/images/key-access-red.svg';
import styles from './styles.module.scss';

function WithoutPermissions() {
  var auth = new Auth();
  const { t } = useTranslation();
  return (
    <PageMessage>
      <PageMessage.Header>
        <IconAccesDenied className={styles.icon} />
      </PageMessage.Header>

      <PageMessage.Content>
        <Typography align="center" component="h3" marginButton={2}>
          {t('common:errors.AccesDenied.heading')}
        </Typography>

        <Margin side="bottom" xs={36}>
          <Typography align="center" variant="headerTitle" component="p">
            {t('common:errors.AccesDenied.messagePermissions')}
          </Typography>
        </Margin>

        <Button
          color="success"
          onClick={() => {
            auth.retryAuth();
          }}>
          {t('common:buttons.retry')}
        </Button>
      </PageMessage.Content>
    </PageMessage>
  );
}

WithoutPermissions.propTypes = {
  onRetry: PropTypes.func,
  t: PropTypes.func,
};

export default WithoutPermissions;
