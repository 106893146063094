import React from 'react';
import PropTypes from 'prop-types';

import { Typography, Button, Grid } from '@gbm/queen-ui-guidelines';
import { useTranslation } from 'react-i18next';

import IconGbmLogo from 'assets/images/gbmacc-platform-black.png';
import styled from 'styled-components';

const Background = styled.div`
  color: #014c97;
`;

function ViewLogIn({ onLogIn }) {
  const { t } = useTranslation();

  return (
    <Grid container className="mt-5">
      <Grid row justify="center">
        <Grid md={8} lg={6} xl={5}>
          <div>
            <div>
              <div className="text-center w-75 m-auto">
                <Typography component="h1">
                  <Background>{t('common:login.message')}</Background>
                </Typography>
              </div>

              <img src={IconGbmLogo} alt="gbm" className="img-fluid mb-5 mt-2" />
              <Button onClick={onLogIn} className="btn-block" color="secondary">
                {t('common:login.button')}
              </Button>
            </div>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
}

ViewLogIn.propTypes = {
  onLogIn: PropTypes.func,
};

export default ViewLogIn;
