import React, { Component } from 'react';
import Mantis from 'api/mantis';
import { Spinner } from '@gbm/queen-ui-guidelines';
import CustPagination from '../../../../components/CustPagination';
import ProcessLogList from './ProcessLogList';
import './index.scss';
import '../../styles.scss';

//import ContractGroup from '../../../ContractGroup/ContractGroup'
//<ContractGroup id={"e5351290-8152-4f6e-88a5-4c918b49fd3c"}/>

/* istanbul ignore next */
class ProcessLog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: '',
      queryResult: [],
      usedSearch: false,
      isLoading: false,
      pagination: {
        total_items: 0,
        page_size: 25,
        page_count: 0,
        page: 1,
        next: '',
        previous: '',
      },
      selectedEditProcessLog: {},
      isOpenModalEditContractGroup: false,
    };

    this.handleIdChange = this.handleIdChange.bind(this);
  }

  /* istanbul ignore next */
  handleIdChange(event) {
    const value = event.target.value;
    this.setState({ id: value });
  }

  handleLoading = e => {
    this.setState({ isLoading: e });
  };

  /* istanbul ignore next */
  handleIdSubmit = () => {
    this.handlePageClick(1);
  };

  handlePageSelected = data => {
    const { pagination } = this.state;
    if (pagination.page !== data.currentPage) this.handlePageClick(data.currentPage);
  };

  handlePageClick = selectedPage => {
    this.handleLoading(true);
    const { pagination } = this.state;
    Mantis.getProcessingLogs({ page: selectedPage, pageSize: pagination.page_size }).then(results => {
      this.handleResults(results);
      this.handleLoading(false);
    });
  };

  /* istanbul ignore next */
  handleResults = results => {
    this.setState({
      pagination: {
        total_items: results.data.pagination_metadata.total_items,
        page_count: results.data.pagination_metadata.page_count,
        page: results.data.pagination_metadata.page,
      },
      queryResult: results.data.items,
      usedSearch: true,
    });
  };

  //#region Modales

  /* istanbul ignore next */
  handleCloseModal = () => {
    this.setState({ isOpenModalEditProcessLog: false });
  };

  /* istanbul ignore next */
  handleSubmit = () => {
    this.setState({ isOpenModalEditProcessLog: false });
  };
  //#endregion

  /* istanbul ignore next */
  handleEdit = element => {
    this.setState({ isOpenModalEditProcessLog: true, selectedEditProcessLog: element });
  };

  /* istanbul ignore next */
  handleDelete = element => {
    Mantis.deleteContractGroupById({ id: element.id }).then(this.handlePageClick(1));
  };

  renderResults() {
    const { queryResult, pagination } = this.state;
    /* istanbul ignore next */
    return queryResult.length === 0 ? (
      <p className="notFoundMessage">Ups! No se encontraron resultados :(</p>
    ) : (
      <div>
        <div> {pagination.total_items} elementos encontrados. </div>
        <ProcessLogList list={queryResult} onEdit={this.handleEdit} onDelete={this.handleDelete} />
      </div>
    );
  }

  /* istanbul ignore next */
  render() {
    const { isLoading, usedSearch, queryResult, pagination } = this.state;

    return (
      <div>
        <h2 className="header">Proceso Corto Log</h2>
        <label>Realice una búsqueda para obtener la información de las ultimas ejecuciones del proceso corto:</label>
        <button className="searchButton" type="button" onClick={this.handleIdSubmit}>
          Buscar
        </button>
        <div>
          {/* Paginador */
          usedSearch && isLoading === false ? (
            <CustPagination
              totalRecords={pagination.total_items}
              pageLimit={10}
              pageNeighbours={2}
              onPageChanged={this.handlePageSelected}
            />
          ) : null}
        </div>
        {/* Lista de resultados */
        isLoading ? (
          <Spinner />
        ) : usedSearch && queryResult !== null && isLoading === false ? (
          this.renderResults()
        ) : null}
      </div>
    );
  }
}
export default ProcessLog;
