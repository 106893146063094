import React from 'react';
import Header from './Header';
import Content from './Content';
import styles from './styles.module.scss';

function PageMessage({ ...others }) {
  return <div className={styles.root} {...others} />;
}

PageMessage.Header = Header;
PageMessage.Content = Content;

export default PageMessage;
