import React, { Component } from 'react';
import ResultsList from './ResultsList';
import Mantis from 'api/mantis';
import './results.scss';
import CustPagination from '../../../../components/CustPagination';
import { Spinner, CardBox, Button, Icon } from '@gbm/queen-ui-guidelines/dist/components';
import CustomDropdown from '../../../../components/CustomDropdown';

class Resultados extends Component {
  constructor(props) {
    super(props);
    var today = new Date();
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    this.state = {
      queryResult: [],
      usedSearch: false,
      isLoading: false,
      loadingCatalogs: false,
      companyList: [],
      selectedCompany: null,
      minDateValue: '2006-01',
      maxDateValue: yyyy + '-' + mm,
      selectedDate: yyyy + '-' + mm,
      pagination: {
        total_items: 0,
        page_size: 25,
        page_count: 0,
        page: 1,
        next: '',
        previous: '',
      },
    };
  }

  componentDidMount() {
    this.handleLoadingCatalogs(true);
    this.setCompanyList();
  }

  setCompanyList() {
    Mantis.getAllContractGroups().then(results => {
      let items = results.data.items;
      this.setState({
        companyList: items,
        selectedCompany: items[0].name,
      });
      this.handleLoadingCatalogs(false);
    });
  }

  formatDate(date) {
    date = new Date(date);
    var dateStr = `${date.getFullYear()}-${('00' + (date.getMonth() + 1)).slice(-2)}-${('00' + date.getDate()).slice(
      -2
    )}`;
    return dateStr;
  }

  handleSelectedDate = e => {
    this.setState({ selectedDate: e.target.value });
  };

  handleCompanyChange = e => {
    this.setState({ selectedCompany: e.name });
  };

  handleLoading = e => {
    this.setState({ isLoading: e });
  };

  handleLoadingCatalogs = e => {
    this.setState({ loadingCatalogs: e });
  };

  /* istanbul ignore next */
  handleSubmit = () => {
    this.handlePageClick(1);
  };

  handlePageSelected = data => {
    const { pagination } = this.state;
    if (pagination.page !== data.currentPage) this.handlePageClick(data.currentPage);
  };

  /* istanbul ignore next */
  handlePageClick = selectedPage => {
    this.handleLoading(true);
    const { pagination, selectedCompany, selectedDate } = this.state;

    /*Last date of month calculation*/
    var lastDayOfMonth = new Date(selectedDate + '-01');
    var mm = lastDayOfMonth.getMonth() + 2; //January is 0!
    var yyyy = lastDayOfMonth.getFullYear();
    lastDayOfMonth = this.formatDate(new Date(yyyy, mm, 0));

    Mantis.getInflationAdjustmentCost({
      contractGroupName: selectedCompany,
      date: lastDayOfMonth,
      page: selectedPage,
      pageSize: pagination.page_size,
    }).then(results => {
      this.handleResults(results);
      this.handleLoading(false);
    });
  };

  handleResults = results => {
    this.setState({
      pagination: {
        total_items: results.data.pagination_metadata.total_items,
        page_count: results.data.pagination_metadata.page_count,
        page_size: results.data.pagination_metadata.page_size,
        page: results.data.pagination_metadata.page,
      },
      queryResult: results.data.items,
      usedSearch: true,
    });
  };

  /* istanbul ignore next */
  renderResults() {
    const { queryResult, pagination } = this.state;
    return queryResult.length === 0 ? (
      <div className="notFoundMessage">
        <p>Ups! No se encontraron resultados :(</p>
      </div>
    ) : (
      <div>
        <div> {pagination.total_items} elementos encontrados. </div>
        <ResultsList list={queryResult} />
      </div>
    );
  }

  /* istanbul ignore next */
  render() {
    const {
      isLoading,
      usedSearch,
      pagination,
      companyList,
      selectedDate,
      minDateValue,
      maxDateValue,
      loadingCatalogs,
    } = this.state;
    return (
      <div>
        <h2 className="header">Resultados</h2>
        <div>
          <CardBox className="divFilter">
            {loadingCatalogs ? (
              <Spinner />
            ) : (
              <div className="row">
                <div className="col-md-3">
                  <div className="black-label">Compañía:</div>
                  <CustomDropdown
                    itemsSource={companyList}
                    value="id"
                    displayName="name"
                    onSelectionChanged={this.handleCompanyChange}
                  />
                </div>
                <div className="col-md-3">
                  <div className="black-label">Fecha:</div>
                  <input
                    type="month"
                    className="white-month-picker"
                    defaultValue={selectedDate}
                    value={selectedDate}
                    min={minDateValue}
                    max={maxDateValue}
                    onChange={this.handleSelectedDate}
                  />
                </div>
                <div className="col-md-2">
                  <div className="mb-1 font-weight-medium">.</div>
                  <Button bordered={false} color="warning" type="button" onClick={this.handleSubmit}>
                    <Icon className="fas fa-search" component="i" />
                  </Button>
                </div>
              </div>
            )}
          </CardBox>
          {usedSearch ? (
            <div className="divPaginator">
              <CustPagination
                totalRecords={pagination.total_items}
                pageLimit={pagination.page_size}
                pageNeighbours={2}
                onPageChanged={this.handlePageSelected}
              />
            </div>
          ) : null}
        </div>
        {isLoading ? (
          <Spinner />
        ) : usedSearch ? (
          this.renderResults()
        ) : (
          <div className="searchText">
            <p>Por favor realice una búsqueda</p>
          </div>
        )}
      </div>
    );
  }
}

export default Resultados;
