import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import '../../../styles.scss';
import { Table, TableColumn, TableRow, TableHead, TableBody, TableCell } from '@gbm/queen-ui-guidelines';

/* istanbul ignore next */
const ItemActive = styled.div`
  background: ${props => (props.isActive ? 'green' : 'red')};
  width: 10px;
  height: 10px;
`;

class InpcList extends Component {
  static propTypes = {
    list: PropTypes.array,
    onEdit: PropTypes.func,
    onDelete: PropTypes.func,
  };

  render() {
    const { list } = this.props;
    const listOfElements = list;

    return (
      <div className="resultsTable">
        <Table variantInherit="bordered">
          <TableHead variant="dark">
            <TableRow>
              <TableColumn className="headerAlignmentCenter">Año</TableColumn>
              <TableColumn className="headerAlignmentCenter">Mes</TableColumn>
              <TableColumn className="headerAlignmentCenter">Valor</TableColumn>
              <TableColumn className="headerAlignmentCenter">Activo</TableColumn>
            </TableRow>
          </TableHead>
          <TableBody>
            {listOfElements.map(element => {
              /* istanbul ignore next */
              return (
                <TableRow key={element.id}>
                  <TableCell>{element.year}</TableCell>
                  <TableCell>{element.month}</TableCell>
                  <TableCell>{element.value}</TableCell>
                  <TableCell>{<ItemActive isActive={element.is_active} />}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    );
  }
}

export default InpcList;
