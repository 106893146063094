import React from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';
import { Grid } from '@gbm/queen-ui-guidelines';

import Header from 'components/Header';
import Sidebar from 'components/Sidebar';

import { getTokenId } from 'utils/localStorage';
import Auth from 'utils/auth';

import styles from './layout.module.scss';

class Layout extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      small: false,
    };

    this.auth = new Auth();
  }

  handleSetSmall = () => {
    const { small } = this.state;

    this.setState({
      small: !small,
    });
  };

  render() {
    const { small } = this.state;
    const { children } = this.props;
    const tokenId = getTokenId();
    const username = this.auth.getTokenUsername(tokenId.value);

    const classesPage = clsx({
      [styles.page]: true,
      [styles.page_small]: small,
    });

    return (
      <div id="wrapper">
        <Header username={username} onSmall={this.handleSetSmall} small={small} />

        <Sidebar small={small} />

        <div className={classesPage}>
          <Grid className="container-fluid">{children}</Grid>
        </div>
      </div>
    );
  }
}

Layout.propTypes = {
  children: PropTypes.node,
};

export default Layout;
