import React, { useState, useEffect } from 'react';
import CustomDropdown from '../../../../../components/CustomDropdown';
import { Button, Notification, Spinner } from '@gbm/queen-ui-guidelines';
import Mantis from 'api/mantis';
import PropTypes from 'prop-types';
import AutoCompleteDropDown from '../../../../../components/AutoCompleteDropDown';

const ProcessForm = ({ handleSubmit }) => {
  var today = new Date();
  var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  var yyyy = today.getFullYear();

  const [issueList, setIssueList] = useState([]);
  const [selectedIssueItem, setSelectedIssueItem] = useState();
  const [contractGroupList, setContractGroupList] = useState([]);
  const [selectedContractGroupItem, setSelectedContractGroupItem] = useState();
  const [selectedBeginDate, setSelectedBeginDate] = useState(yyyy + '-' + mm);
  const [selectedEndDate, setSelectedEndDate] = useState(yyyy + '-' + mm);
  const [isLoading, setIsLoading] = useState(true);
  const [issuesToProcessList, setIssuesToProcessList] = useState([]);
  const [error, setError] = useState({ error: false, errorMessage: 'No Error' });

  let contractGroupsLoaded = false;
  let issuesLoaded = false;

  /* istanbul ignore next */
  useEffect(() => {
    updateContractGroupList();
    updateIssueList();
  }, []);

  /* istanbul ignore next */
  const updateContractGroupList = () => {
    try {
      Mantis.getAllContractGroups().then(results => {
        const items = results.data.items;
        setContractGroupList(items);
        setSelectedContractGroupItem(items[0]);
        contractGroupsLoaded = true;
        setIsLoading(!(contractGroupsLoaded && issuesLoaded));
      });
    } catch (error) {
      setError({ error: true, errorMessage: 'Tenemos un problema al obtener la lista de compañías' });
    }
  };

  /* istanbul ignore next */
  const updateIssueList = () => {
    try {
      Mantis.getAllIssues().then(results => {
        const items = results.data.items;
        setIssueList(items);
        issuesLoaded = true;
        setIsLoading(!(contractGroupsLoaded && issuesLoaded));
      });
    } catch (error) {
      setError({ error: true, errorMessage: 'Tenemos un problema al obtener la lista de emisoras' });
    }
  };

  /* istanbul ignore next */
  function addIssue(e) {
    if (e != null) {
      if (!checkForExistingIssueInList(e)) {
        let newData = [...issuesToProcessList, e];
        setIssuesToProcessList(newData);
      } else {
        setError({ error: true, errorMessage: 'La emisora ' + e.ticker + ' ya esta en la lista.' });
      }
    } else {
      setError({ error: true, errorMessage: 'No se ha seleccionado una emisora para agregar.' });
    }
  }

  /* istanbul ignore next */
  function removeIssue(e) {
    var newList = issuesToProcessList.filter(item => item.id != e.id);
    setIssuesToProcessList(newList);
  }

  /* istanbul ignore next */
  const checkForExistingIssueInList = issue => {
    const result = issuesToProcessList.filter(i => i.id === issue.id);

    if (result.length > 0) {
      return true;
    }

    return false;
  };

  /* istanbul ignore next */
  function onSubmit() {
    setError({ error: false, errorMessage: 'Not error' });
    let array = [];
    try {
      if (issuesToProcessList.length > 0) {
        issuesToProcessList.forEach(issue => {
          var processingTask = {
            id: Mantis.createNewGuid(),
            description:
              'Procesamiento: Contract Group: ' + selectedContractGroupItem.name + ', Emisora:' + issue.ticker,
            contract_group_id: selectedContractGroupItem.id,
            issue_id: issue.id,
            issue_ticker: issue.ticker,
            begin_year_month: selectedBeginDate.replace('-', ''),
            end_year_month: selectedEndDate.replace('-', ''),
            created_by: 'pruebas-dev',
            created_at: new Date(),
          };

          array.push(processingTask);
        });
        handleSubmit(array);
      } else {
        setError({ error: true, errorMessage: 'No se han agregado emisoras para procesar' });
      }
    } catch (error) {
      setError({ error: true, errorMessage: error });
    }
  }

  /* istanbul ignore next */
  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div>
          <div className="row">
            <div className="col-md-4">
              <div>
                <div className="black-label">Compañía:</div>
                <CustomDropdown
                  itemsSource={contractGroupList}
                  value="id"
                  displayName="name"
                  onSelectionChanged={e => {
                    setSelectedContractGroupItem({ id: e.id, name: e.name });
                  }}
                />
              </div>
              <div>
                <div className="black-label">Fecha inicial:</div>
                <input
                  type="month"
                  className="white-month-picker"
                  value={selectedBeginDate}
                  onChange={e => {
                    setSelectedBeginDate(e.target.value);
                  }}
                />
              </div>
              <div>
                <div className="black-label">Fecha final:</div>
                <input
                  type="month"
                  className="white-month-picker"
                  value={selectedEndDate}
                  onChange={e => {
                    setSelectedEndDate(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="col-md-8">
              <div>
                <div className="black-label">Seleccione emisoras a procesar:</div>
                <div className="row">
                  <div className="col-md-8">
                    <AutoCompleteDropDown
                      itemsSource={issueList}
                      value="id"
                      displayName="ticker"
                      onSelectionChanged={e => {
                        setSelectedIssueItem({ id: e.id, ticker: e.ticker });
                      }}
                    />
                  </div>
                  <div className="col-md-2">
                    <Button type="button" className="btn-eject" onClick={() => addIssue(selectedIssueItem)}>
                      Agregar
                    </Button>
                  </div>
                </div>
              </div>
              <div>
                <hr />
                {issuesToProcessList.length > 0 ? (
                  <ul className="issue-list">
                    {issuesToProcessList.map(element => {
                      return (
                        <li key={element.id}>
                          <div className="name">{element.ticker}</div>
                          <button type="button" className="remove" onClick={() => removeIssue(element)}>
                            <i className="fas fa-times" component="i" />
                          </button>
                        </li>
                      );
                    })}
                  </ul>
                ) : (
                  <div className="text-center"> No se ha agregado ninguna emisora. </div>
                )}
                <hr />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4" />
            <div className="col-md-8">
              <div className="float-right">
                <Button type="button" className="btn-eject" size="sm" onClick={() => onSubmit()}>
                  Ejecutar
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
      <Notification open={error.error} color="danger" colorInherit>
        {error.errorMessage}
      </Notification>
    </>
  );
};

ProcessForm.propTypes = {
  handleSubmit: PropTypes.func,
};

export default ProcessForm;
