import React, { Component } from 'react';
import { Table, TableColumn, TableRow, TableHead, TableBody } from '@gbm/queen-ui-guidelines';
class Resumen extends Component {
  render() {
    return (
      <div>
        <h4>Resumen Ajuste al Costo</h4>

        <form className="form-inline">
          <Table>
            <TableHead>
              <TableRow>
                <TableColumn>Títulos</TableColumn>
                <TableColumn>Precio</TableColumn>
                <TableColumn>Costo</TableColumn>
                <TableColumn>Resultado</TableColumn>
              </TableRow>
            </TableHead>
            <TableBody></TableBody>
          </Table>
        </form>
      </div>
    );
  }
}
export default Resumen;
