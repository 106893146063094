// Needed for redux-saga es6 generator support
import 'core-js';

import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';

import { Spinner } from '@gbm/queen-ui-guidelines';
import '@gbm/queen-ui-guidelines/css/index.css';

import styled from 'styled-components';
import history from 'utils/history';

import configureStore from './configureStore';

import App from './App';

import * as serviceWorker from './serviceWorker';

import './i18n';

import './assets/scss/theme.scss';

import NewRelic from 'components/NewRelic';
import config from 'config';
const env = config();
const { APP_ENV } = env;

const initialState = {};
const store = configureStore(initialState, history);
const MOUNT_NODE = document.getElementById('root');
const Background = styled.div`
  background: #ffffff;
`;
const render = () => {
  ReactDOM.render(
    <Background>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <Suspense fallback={<Spinner color="secondary" variant="grow" size="lg" component="div" />}>
            <NewRelic environment={APP_ENV} />
            <App />
          </Suspense>
        </ConnectedRouter>
      </Provider>
    </Background>,
    MOUNT_NODE
  );
};

if (module.hot) {
  module.hot.accept(['./App'], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE);
    render();
  });
}

render();

serviceWorker.unregister();
