import React, { Component } from 'react';
import { Table, TableColumn, TableRow, TableHead, TableBody, Button, FormGroup } from '@gbm/queen-ui-guidelines';

class Variables extends Component {
  render() {
    return (
      <div>
        <h4>Variables Fiscales</h4>

        <form className="form-inline">
          <FormGroup classname="">
            <Button color="purple" size="sm" widths="lg">
              Agregar
            </Button>
          </FormGroup>

          <Table>
            <TableHead>
              <TableRow>
                <TableColumn>Variable</TableColumn>
                <TableColumn>Fecha</TableColumn>
                <TableColumn>Valor</TableColumn>
              </TableRow>
            </TableHead>
            <TableBody></TableBody>
          </Table>
        </form>
      </div>
    );
  }
}
export default Variables;
