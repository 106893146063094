import decode from 'jwt-decode';
import get from 'lodash/get';
import config from '../config';
import { getTokenId, removeTokenId } from './localStorage';
import { API_EP } from './constants';

const env = config();
const { EP_AUTH_URL, EP_AUTH_CLIENT_ID, EP_AUTH_REDIRECT_URI, EP_AUTH_LOGOUT_URI, ACCOUNTING_GROUP } = env;
export default class Auth {
  /**
   * replace the route for url ui amazoncognito and remove
   * tokenId of localStorage
   * @returns {window} url ui amazoncognito
   */
  redirectToLogin() {
    return window.location.replace(
      `${EP_AUTH_URL}${API_EP.LOGIN}client_id=${EP_AUTH_CLIENT_ID}&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+profile&redirect_uri=${EP_AUTH_REDIRECT_URI}`
    );
  }

  signOut() {
    removeTokenId();
    return window.location.replace(
      `${EP_AUTH_URL}${API_EP.LOGOUT}client_id=${EP_AUTH_CLIENT_ID}&logout_uri=${EP_AUTH_LOGOUT_URI}`
    );
  }

  redirectWithWindow(target = '/') {
    return window.location.replace(target);
  }

  retryAuth() {
    removeTokenId();
    this.redirectToLogin();
  }

  /**
   * Returns the expiration date of a token.
   *
   * @param {string} token - A JWT token
   * @returns {date} Expiration Date
   */
  getTokenExpiration(token) {
    const decoded = decode(token);

    if (!decoded.exp) {
      return null;
    }

    const date = new Date(0);
    date.setUTCSeconds(decoded.exp);
    return date;
  }

  getTokenGroupAuthorization(token) {
    const decoded = decode(token);

    var groups = decoded['custom:groups']
      ?.replace('[', '')
      .replace(']', '')
      .replace(/\s/g, '')
      .split(',');

    if (!groups || !groups.includes(ACCOUNTING_GROUP)) {
      removeTokenId();
      return false;
    }

    return true;
  }

  /**
   * Returns false when token is still valid,
   * returns true otherwise.
   *
   * @returns {boolean}
   */
  isTokenExpired() {
    const tokenId = getTokenId().value;
    const expirationDate = this.getTokenExpiration(tokenId);
    const isTokenExpired = expirationDate === null ? false : !(expirationDate.valueOf() > new Date().valueOf());

    return isTokenExpired;
  }

  isValidToken() {
    const valid = get(getTokenId(), 'value', false);

    return valid;
  }

  /**
   * Returns the username of a token
   *
   * @param {string} token - A JWT token
   * @returns {string} Username or full name
   */
  getTokenUsername(token) {
    const decodeMapping = decode(token);

    const user = decodeMapping.given_name || decodeMapping['cognito:username'] || null;

    return user;
  }
}
