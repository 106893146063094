/**
 * Utility functions for interacting with localStorage
 */
/* istanbul ignore file */
import store from 'store';

/**
 * Saves a key-value to localStorage
 *
 * @param {string} key - Key.
 */
export const setTokenId = token => {
  /* istanbul ignore next */
  try {
    return store.set('tokenId', { value: token });
  } catch (err) {
    return null;
  }
};

/**
 * Saves a key to localStorage
 *
 * @param {string} key - Key.
 */
export const getTokenId = () => {
  /* istanbul ignore next */
  try {
    return store.get('tokenId');
  } catch (err) {
    return null;
  }
};

/**
 * Remove a key to localStorage
 *
 * @param {string} key - Key.
 */
export const removeTokenId = () => {
  /* istanbul ignore next */
  try {
    return store.remove('tokenId');
  } catch (err) {
    return null;
  }
};
