import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import Auth from 'utils/auth';
import { getTokenId, setTokenId } from 'utils/localStorage';
import Api from 'api';

class SignIn extends React.PureComponent {
  constructor(props) {
    super(props);
    this.auth = new Auth();
    this.state = {
      isLogin: false,
      isAuthorized: false,
    };
  }

  componentDidMount() {
    const { location } = this.props;
    const [, code] = location.search.split('?code=');
    const tokenId = getTokenId();

    if (code) {
      this.handleRequestToken(code);
    }

    if ((!code && !tokenId) || (tokenId && this.auth.isTokenExpired())) {
      this.auth.redirectToLogin();
    }
  }

  handleRequestToken = code => {
    Api.requestToken(code)
      .then(response => {
        const { data } = response;
        setTokenId(data.id_token);
        this.setState({
          isLogin: true,
          isAuthorized: this.auth.getTokenGroupAuthorization(data.id_token),
        });
      })
      .catch(() => {
        /* istanbul ignore next */
        this.auth.redirectToLogin();
      });
  };

  render() {
    const { isLogin, isAuthorized } = this.state;
    const tokenId = getTokenId();

    if (isLogin || tokenId) {
      return (
        <React.Fragment>
          <Redirect to={isAuthorized ? { pathname: '/' } : { pathname: '/without-permissions' }} />
        </React.Fragment>
      );
    }

    return null;
  }
}

SignIn.propTypes = {
  location: PropTypes.object,
};

export default SignIn;
